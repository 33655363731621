<template>
<div class="item">
  <div class="top">
    <div class="fl flex">
      <img :src="getItem.iconUrl">
    </div>
    <div class="info">
      <div class="h2">{{getItem.name}}</div>
      <div class="desc"><p ref="scroll" :style="{ animation: scrollpx }">{{getItem.desc}}</p></div>
    </div>
  </div>
  <div class="flex bottom swiper-node">
    <div class="swiper-wrapper">
      <div v-for="(imgArr, index) in getItem.img" :key="index" class="flex swiper-slide">
        <img v-for="img in imgArr" :key="img" :big="getItem.baseUrl+img" :src="getItem.baseUrl+img+'?x-oss-process=image/resize,h_230,w_300'">
      </div>
    </div>
    <!-- <div class="swiper-button-prev nprev"></div> -->
    <!-- <div class="swiper-button-next nnext"></div> -->
  </div>
  <transition name="fade">
    <div class="flex modal" v-if="select" @click="select=null">
      <Icon class="close" type="md-close" />
      <img :src="select">
    </div>
  </transition>
</div>
</template>
<script>
export default {
  props: {
    cur: {
      default: 0,
      type: Number
    },
    imgList: {
      default: [],
      type: Array
    }
  },
  data() {
    return {
      swiper: null,
      select: null,
      pscroll: 0
    }
  },
  computed: {
    getItem() {
      return this.imgList[this.cur]
    },
    scrollpx() {
      if (this.pscroll > 0) {
        return `hscroll ${this.pscroll}s 2s infinite alternate linear`
      } else {
        'none'
      }
    }
  },
  watch: {
    cur(val) {
      this.swiper.destroy(false);
      this.pscroll = 0
      this.$nextTick(()=> {
        this.pscroll = (this.$refs.scroll.offsetWidth - 828) / 20
        this.swiper = new Swiper('.swiper-node', {
          loop: true,
          autoplay: true,
          // navigation: {
          //   nextEl: '.nnext',
          //   prevEl: '.nprev',
          // },
        })
        this.swiper.el.onmouseover = function(){
          this.swiper.autoplay.stop()
        }
        this.swiper.el.onmouseleave = function(){
          this.swiper.autoplay.start()
        }
      })
    }
  },
  mounted() {
    let that = this
    this.swiper = new Swiper('.swiper-node', {
      loop: true,
      autoplay: true,
      navigation: {
        nextEl: '.nnext',
        prevEl: '.nprev',
      },
    })
    this.swiper.el.onclick = function(e){
      if (e.srcElement.attributes.big.value) {
        that.select = e.srcElement.attributes.big.value
      }
    }
    this.swiper.el.onmouseover = function(){
      this.swiper.autoplay.stop()
    }
    this.swiper.el.onmouseleave = function(){
      this.swiper.autoplay.start()
    }
  }
}
</script>
<style lang="less" scoped>
.img-wrap {
  width: 100%;
  height: 100%;
  padding-top: 35px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.item {
  cursor: pointer;
  border-radius: 3px;
  margin: 30px 0;
  margin-bottom: 0;
  overflow: hidden;
  .top {
    height: 80px;
    background: #6CB0FF;
    .fl {
      width: 120px;
      height: 80px;
      background: #4094F5;
      img {
        max-height: 65px;
        max-width: 105px;
      }
    }
    .info {
      margin-left: 122px;
      .h2 {
        height: 50px;
        color: #FFF;
        font-size: 20px;
        font-weight: 500;
        line-height: 50px;
        text-align: center;
      }
      .desc {
        height: 30px;
        color: #FFF;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        background: #4094F5;
        padding: 0 10px;
        overflow: hidden;
        p {
          display: inline-block;
          white-space: nowrap;
        }
      }
    }
  }
}
.bottom {
  padding-bottom: 30px;
  .swiper-slide {
    height: 700px;
    justify-content: space-between;
    flex-wrap: wrap;
    img {
      max-height: 240px;
      border-radius: 4px;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.modal {
  position: fixed;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .close {
    position: fixed;
    top: 10%;
    z-index: 100001;
    right: 10%;
    font-size: 32px;
    color: #FFF;
  }
  img {
    max-width: 80%;
    max-height: 80%;
  }
}
</style>

<template>
  <div class="first">
    <br>
    <ATitle text="第三届回顾" icon="6"></ATitle>
    <br>
    <div class="first-info center tc">
      <div class="prv">
        <div class="fl">
          <video poster="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2020/videoPoster.jpg" controls="controls" src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/attachments/216122340946959-%E7%AC%AC%E4%B8%89%E5%B1%8A%E5%8D%9A%E8%A7%88%E4%BC%9A%E5%AE%A3%E4%BC%A0%E7%89%87.mp4"></video>
        </div>
        <div class="flex info">
          <div class="item"><h1>122家</h1><p>龙头企业参展</p></div>
          <div class="item"><h1>3.2万人次</h1><p>线下观展</p></div>
          <div class="item"><h1>超300万人次</h1><p>参与云展和直播</p></div>
          <div class="item"><h1>16个</h1><p>重大产业项目签约</p></div>
          <div class="item"><h1>480亿元</h1><p>总投资</p></div>
          <div class="item"><h1>116名</h1><p>媒体记者</p></div>
        </div>
      </div>
    </div>
    <CTitle text="开幕式主旨论坛"></CTitle>
    <div class="f-swiper"><router-link tag="a" to="/first-forum?thread=24"><Swiper baseUrl="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/论坛/开幕式暨主旨论坛/论坛照片/" :list="mainImgList"></Swiper></router-link></div>
    <br>
    <CTitle text="主题论坛"></CTitle>
    <div class="forum-outer">
      <div class="flex forum-wrap center">
        <router-link tag="a" to="/first-forum?thread=25" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/论坛/新基建和智慧高速论坛/封面图.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">新基建和智慧高速论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=26" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/论坛/强港论坛-中国港口高质量发展报告发布会/封面图.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text" style="line-height: 20px;padding-top: 10px;">强港论坛·中国港口高质量发展报告<br>（2020）发布会</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=27" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/论坛/智能轨道发展论坛/封面图.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">智能轨道发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=28" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/论坛/长三角地下空间综合发展高峰论坛/封面图.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">长三角地下空间综合发展高峰论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=29" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/论坛/四港联动发展论坛/封面图.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">“四港”联动发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=30" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/论坛/F5G助力智慧交通发展论坛/封面图.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">F5G助力智慧交通发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=31" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/论坛/第三届未来交通创新发展论坛/封面图.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">第三届未来交通创新发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=32" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/论坛/第二届综合交通创新创业大赛总决赛暨颁奖仪式/封面图.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text" style="line-height: 20px;padding-top: 10px;">第二届综合交通创新创业大赛总决赛<br>暨颁奖仪式</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=33" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/论坛/航天技术助力交通产业发展论坛/封面图.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">航天技术助力交通产业发展论坛</div>
        </router-link>
      </div>
    </div>
    <CTitle text="博览会照片"></CTitle>
    <div class="elevator-wrap center">
      <div class="fl elevator">
        <div class="flex item" @click="cur=0" :class="cur==0&&'cur'">轨道<br>交通馆</div>
        <div class="flex item" @click="cur=1" :class="cur==1&&'cur'">航空<br>航天馆</div>
        <div class="flex item" @click="cur=2" :class="cur==2&&'cur'">智慧<br>交通馆</div>
        <div class="flex item" @click="cur=3" :class="cur==3&&'cur'">物流与<br>应急安全馆</div>
        <div class="flex item" @click="cur=4" :class="cur==4&&'cur'">智能<br>装备馆</div>
        <div class="flex item" @click="cur=5" :class="cur==5&&'cur'">技能<br>大赛馆</div>
      </div>
      <div class="sponsor-list">
        <SwiperNode :cur="cur" :imgList="imgList"></SwiperNode>
      </div>
    </div>
    <div class="annual-elevator">
      <router-link tag="div" class="annual" to="/fifth">第五届</router-link>
      <router-link tag="div" class="annual" to="/fourth">第四届</router-link>
      <div class="annual cur">第三届</div>
      <router-link tag="div" class="annual" to="/second">第二届</router-link>
      <router-link tag="div" class="annual" to="/first">第一届</router-link>
    </div>
  </div>
</template>
<script>
import Swiper from '@/components/Swiper'
import SwiperNode from '@/components/SwiperNode'
export default {
  components: {
    Swiper,
    SwiperNode
  },
  data() {
    return {
      cur: 0,
      mainImgList: ['1G8A2841.jpg','1G8A2894.jpg','1G8A3123.jpg','6Y1F1390.jpg','64611661.jpg','默认图.jpg','微信图片_20210202170657.jpg'],
      imgList: [
        {
          name: '轨道交通馆',
          desc: '重点展示地铁、轻轨、单轨、有轨电车、磁悬浮、市域铁路、城际铁路、重载铁路、高速铁路等相关系统的新技术、新设备、新理念',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/博览会照片/轨道交通馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area1.png',
          img: [['DSC_3228.jpg','DSC_3233.jpg','DSC_3241.jpg','DSC_3242.jpg','DSC_3256.jpg','DSC_3261.jpg','DSC_3264.jpg','DSC_3275.jpg','DSC_3280.jpg'],['DSC_3284.jpg','DSC_3289.jpg','DSC_3296.jpg','DSC_3303.jpg','DSC_3324.jpg','DSC_3375.jpg','DSC_3605.jpg','DSC_3632.jpg','DSC_3643.jpg'],['DSC_3680.jpg','DSC_3685.jpg','DSC_3688.jpg','DSC_3695.jpg','DSC_3695.jpg','DSC_3706.jpg','DSC_3711.jpg','DSC01448.jpg','F83A0568.jpg']],
        },{
          name: '航空航天馆',
          desc: '重点展示航空航天和通用航空智能制造技术与装备及零部件、航空新材料新技术与设备、智慧机场智能化设备、临空经济区及航空产业园等',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/博览会照片/航空航天馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area4.png',
          img: [['DSC_3034.jpg','DSC_3037.jpg','DSC_3044.jpg','DSC_3045.jpg','DSC_3053.jpg','DSC_3056.jpg','DSC_3082.jpg','DSC_3096.jpg','DSC_3103.jpg'],['DSC_3140.jpg','DSC_3142.jpg','DSC_3152.jpg','DSC_3202.jpg','DSC_3210.jpg','DSC01521.jpg','F83A2997.jpg','F83A3003.jpg','F83A3051.jpg']],
        },{
          name: '智慧交通馆',
          desc: '重点展示交通智能化建设最新技术成果、城市大交通建设成果和规划、公共交通及交通管理设备技术和解决方案、智能停车解决方案、智能网联汽车、新能源汽车等',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/博览会照片/智慧交通馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area8.png',
          img: [['DSC_3329.jpg','DSC_3341.jpg','DSC_3359.jpg','DSC_3928.jpg','DSC_3962.jpg','DSC_3980.jpg','DSC_4002.jpg','DSC01482.jpg','F83A0682.jpg'],['F83A1186.jpg','F83A1189.jpg','F83A1200.jpg','F83A1204.jpg','F83A1205.jpg','F83A1210.jpg','F83A1226.jpg','F83A2067.jpg','F83A2134.jpg']],
        },{
          name: '物流与应急安全馆',
          desc: '该馆分为两大板块：物流板块重点展示航空物流、港口物流、跨境电商物流新模式，四港联动融合，智慧物流解决方案与物流技术设备，智能仓储、自动化分拣、新能源物流车辆及配套设备，冷链物流与生鲜配送新技术，新型环保包装材料装备，物流综合体、物流平台和金融服务新模式等；应急板块重点展示道路、桥隧、水上、航运、航空救援等交通场景下的安全监控及应急救援技术装备，疫情管控条件下的智控设备、无人配送设备等，并通过VR、AR、流媒体等高科技手段模拟再现真实场景',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/博览会照片/物流与应急安全馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area2.png',
          img: [['F83A0595.JPG','F83A0598.JPG','F83A0601.JPG','F83A1020.JPG','F83A1029.JPG','F83A1032.JPG','F83A1033.JPG','F83A1042.JPG','F83A1050.JPG'],['F83A1054.JPG','F83A1066.JPG','F83A1071.JPG','F83A1074.JPG','F83A1075.JPG','F83A1078.JPG','F83A1084.JPG','F83A2138.jpg','F83A3127.jpg']],
        },{
          name: '智能装备馆',
          desc: '重点展示交通产业智能制造设备、港航设备（船舶修造、海洋工程、钻探和生产平台、海洋开发设备、大型钢结构、集装箱等）、工程机械（挖掘机械、起重机械、气动工具等）',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/博览会照片/智能装备馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area3.png',
          img: [['F83A0607.JPG','F83A1089.JPG','F83A1092.JPG','F83A1093.JPG','F83A1099.JPG','F83A1105.JPG','F83A1111.JPG','F83A1112.JPG','F83A1116.JPG'],['F83A1119.JPG','F83A1132.JPG','F83A1145.JPG','F83A1148.JPG','F83A1149.JPG','F83A1158.JPG','F83A1162.JPG','F83A1168.JPG','F83A4494.jpg']]
        },{
          name: '技能大赛馆',
          desc: '整合2020年中国技能大赛－第十二届全国交通运输行业职业技能大赛资源，重点展示城市轨道交通信号工、道路货运汽车驾驶员、港口客运员、起重装卸机械操作工等工种的应用型新技术、新设备',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2020/博览会照片/技能大赛馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area7.png',
          img: [['DSC_3367.jpg','DSC_3370.jpg','DSC_3371.jpg','DSC_3378.JPG','DSC_3382.JPG','DSC_3388.JPG','DSC_3393.JPG','DSC_3406.JPG','DSC_3825.jpg'],['DSC_3827.jpg','DSC_3830.jpg','DSC_3837.jpg','DSC_3842.jpg','DSC_3846.jpg','DSC_3853.jpg','DSC_3858.jpg','DSC_3874.jpg','F83A0646.JPG']]
        }
      ],
    }
  }
}
</script>

<style lang="less" scoped>
.first {
  background: url('../assets/views_bg.png') no-repeat scroll bottom / 100%;
  .first-info {
    .prv {
      margin: 20px 0;
      height: 240px;
      .fl {
        width: 423px;
        height: 238px;
        video {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          box-shadow: 0 0 20px rgba(28, 36, 52, 0.5);
        }
      }
      .info {
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-left: 50px;
        .item {
          text-align: center;
          height: 120px;
          width: 200px;
          padding: 20px 0;
          color: #125FAD;
          h1 {
            font-size: 28px;
          }
          p {
            color: #666;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .f-swiper {
    background: #F3F3F3;
    padding: 40px 0;
  }
  .forum-outer {
    background: url('../assets/maze_bg.svg') repeat;
    padding: 20px 0;
  }
  .forum-wrap {
    justify-content: space-around;
    flex-wrap: wrap;
    .forum-item {
      display: block;
      cursor: pointer;
      width: 310px;
      height: 250px;
      border-radius: 4px;
      margin-bottom: 30px;
    }
    .img {
      width: 310px;
      height: 170px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      overflow: hidden;
      img {
        max-height: 170px;
        width: auto;
        transition: all ease-out 0.2s;
      }
    }
    .forum-item:hover {
      img {
        transform: scale(1.05);
      }
    }
    .text {
      height: 60px;
      color: #333;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      border-top: 1px solid #F0F0F0;
      line-height: 60px;
      background: #F0F0F0;
    }
  }
  .elevator-wrap {
    .elevator {
      border-radius: 4px;
      width: 90px;
      height: 482px;
      left: 10px;
      border: 1px solid #D6D6D6;
      background: #FFF;
      div {
        margin: 0 auto;
      }
      .item {
        cursor: pointer;
        height: 80px;
        width: 90px;
        border-bottom: 1px solid #D6D6D6;
        color: #666666;
        font-size: 16px;
        font-weight: 400px;
        text-align: center;
      }
      .item:last-child {
        border-bottom: none;
      }
      .cur {
        background: #2585E5;
        color: #FFF;
      }
    }
  }
  .sponsor-list {
    padding-left: 110px;
  }
}
.annual-elevator {
  position: fixed;
  right: calc(50% - 640px);
  top: 20%;
  border: 1px solid #D6D6D6;
  background: #FFF;
  .annual {
    cursor: pointer;
    height: 50px;
    width: 80px;
    border-bottom: 1px solid #D6D6D6;
    color: #666666;
    font-size: 16px;
    line-height: 50px;
    font-weight: 400px;
    text-align: center;
  }
  .annual:last-child {
    border-bottom: none;
  }
  .cur {
    background: #2585E5;
    color: #FFF;
  }
}
</style>

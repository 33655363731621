<template>
  <div class="swiper">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="item in list" class="flex swiper-slide"><img :src="baseUrl+item+'?x-oss-process=image/resize,w_800'"/></div>
      </div>
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>
<script>
export default {
  props: {
    baseUrl: {
      default: '',
      type: String
    },
    list: {
      default: [],
      type: Array
    }
  },
  data() {
    return {
      swiper: null
    }
  },
  mounted() {
    this.swiper = new Swiper('.swiper .swiper-container', {
      watchSlidesProgress: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: true,
      loopedSlides: 5,
      autoplay: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      // pagination: {
      //   el: '.swiper-pagination',
      //   // clickable :true,
      // },
      on: {
        progress: function(progress) {
          for (let i = 0; i < this.slides.length; i++) {
            let slide = this.slides.eq(i)
            let slideProgress = this.slides[i].progress
            let modify = 1
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1
            }
            let translate = slideProgress * modify * 260 + 'px'
            let scale = 1 - Math.abs(slideProgress) / 5
            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
            slide.transform('translateX(' + translate + ') scale(' + scale + ')')
            slide.css('zIndex', zIndex)
            slide.css('opacity', 1)
            if (Math.abs(slideProgress) > 3) {
              slide.css('opacity', 0)
            }
          }
        },
        setTransition: function(transition) {
          for (let i = 0; i < this.slides.length; i++) {
            let slide = this.slides.eq(i)
            slide.transition(transition)
          }
        }
      }
    })
    this.swiper.el.onmouseover = function(){
      this.swiper.autoplay.stop()
    }
    this.swiper.el.onmouseleave = function(){
      this.swiper.autoplay.start()
    }
  }
}
</script>
<style lang="less" scoped>
.swiper {
	position: relative;
	width: 1000px;
  margin: 0 auto;
  .swiper-slide {
    width: 800px;
    height: 533px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      opacity: 0.3;
    }
  }
  .swiper-slide-active {
    img {
      opacity: 1 !important;
    }
  }
}
</style>
